.footer-container { 
    height: 40px; /* Fixed height for Footer */
    background-color: #000000; /* Example background color */
    padding: 0 20px;
    display: flex; /* Use flexbox layout */
    align-items: center; /* Vertically center content */
}

.slogan { 
    margin-left: auto; /* Push slogan to the right */
    margin-right: auto; /* Push slogan to the left */
    color: #ffffff; /* Slogan color */
    text-decoration: none; /* Remove default link underline */
    text-align: center; /* Center the text horizontally */
}
