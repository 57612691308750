/* MobileNavbar.css */
.mobile-navbar-container {
    background-color: rgb(0, 0, 0); /* Black with 80% opacity */
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.mobile-navbar-header {
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center;
}

.mobile-navbar-logo {
    text-decoration: none;
}

.menu-button {
    cursor: pointer;
    font-size: 1.5rem;
    color: #fff; /* White color for the icon */
    margin-left: 60px; /* Add space between list icon and edge */
}

/* Style for the list icon */
.list-icon {
    color: #fff; /* White color for the icon */
}

.mobile-navbar-links {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.mobile-navbar-link {
    text-decoration: none;
    color: #fff; /* White text color */
    padding: 8px 0;
    border-bottom: 1px solid #eee;
    transition: font-weight 0.3s ease; /* Transition for font weight */
}

.mobile-navbar-link:last-child {
    border-bottom: none;
}

.mobile-navbar-link:hover {
    font-weight: bold; /* Change to bold on hover */
}

.mobile-navbar-link:hover {
    font-weight: bold; /* Change to bold on hover */
}

.mobile-navbar-cart-icon { 
    font-weight: bold; 
}

.mobile-navbar-cart-icon {
    font-size: 1.2rem;
}
