/* Style the form container */
.title-contactme {
  font-size: 24px; /* Adjust font size for smaller screens */
  font-weight: bold;
  margin: 20px auto;
  text-align: center;
}

.contact-form {
  width: 90%; /* Adjust width to make it responsive */
  max-width: 400px;
  margin: 10vh auto; /* Center the form horizontally and add space from top and bottom */
  padding: 5vw;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Style form labels */
.contact-form__label {
  display: block;
  margin-bottom: 5px;
}

/* Style form inputs */
.contact-form__input,
.contact-form__textarea {
  width: 100%;
  padding: 10px; /* Adjust padding for smaller screens */
  margin-bottom: 2vh;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding and border are included in width */
  font-family: inherit; /* Use the same font family as the parent element */
  font-size: inherit; /* Use the same font size as the parent element */
}

/* Style submit button */
.contact-form__submit {
  background-color: #007bff;
  color: #fff;
  padding: 12px 24px; /* Adjust padding for a more proportional size */
  border: none;
  border-radius: 25px; /* Increase border-radius for a rounder button */
  cursor: pointer;
  font-size: 16px; /* Adjust font size for better readability */
  transition: background-color 0.3s ease; /* Add transition for smoother hover effect */
}

.contact-form__submit:hover {
  background-color: #0056b3;
}

/* Style state message */
.contact-form__message {
  margin-top: 2vh;
  padding: 10px; /* Adjust padding for smaller screens */
  border-radius: 4px;
}

.contact-form__success {
  background-color: #000000;
  color: #ffffff;
  border-color: #000000;
}

/* Media Query for smaller screens */
@media only screen and (max-width: 600px) {
  .title-contactme {
    font-size: 20px;
  }

  .contact-form {
    padding: 5%;
  }

  .contact-form__input,
  .contact-form__textarea {
    padding: 8px;
  }

  .contact-form__submit {
    padding: 8px 16px;
    font-size: 14px;
  }

  .contact-form__message {
    padding: 8px;
  }
}
