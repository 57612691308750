/* Styling for the container of the welcome message */
.welcome-container {
  display: flex; /* Use flexbox layout */
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  flex-direction: column; /* Align items vertically */
}

.welcome-title {
  font-size: 32px;
  font-weight: bold;
  margin: 20px auto;
  text-align: center;
}

.about-me-container {
  display: flex;
  flex-direction: row-reverse; /* Reverse the order of the elements */
  align-items: center; /* Center items vertically */
  margin-bottom: 100px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.baby-photo {
  flex: 1; /* Take up one third of the container */
  margin-left: 20px; /* Add some spacing between the text and the image */
}

.welcome-text {
  flex: 2; /* Take up two thirds of the container */
  text-align: left; /* Align text to the left */
}


/* Styling for the container of the images */
.unique-photos-of-me-container {
  display: flex;
  justify-content: center; /* Center images horizontally */
  flex-wrap: wrap; /* Allow images to wrap to the next line */
  max-width: 1200px; /* Maximum width of the container */
  
}

/* Styling for the image container */
.unique-image-container {
  width: 23%; /* Adjust as needed to fit all images in one row */
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}

/* Styling for the image */
.unique-image-container img {
  width: 100%;
  height: 600px; /* Set a fixed height for the images */
  object-fit: cover; /* Crop images */
}

/* Styling for the image overlay */
.unique-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styling for the image overlay on hover */
.unique-image-container:hover .unique-image-overlay {
  opacity: 1;
}

.baby-photo {
  margin-top: 20px; /* Adjust the value as needed for the desired buffer */
}

.baby-photo img {
  width: 200px; /* Set the desired width */
  height: auto; /* Allow the image to adjust its height */
}


/* Media Query for mobile devices */
@media only screen and (max-width: 768px) {
  .welcome-title {
    font-size: 28px; /* Decrease font size for mobile */
    margin: 10px auto; /* Adjust margin for mobile */
  }

  .about-me-container {
    flex-direction: column; /* Switch to column layout for mobile */
    margin-bottom: 50px; /* Reduce margin for mobile */
    padding: 20px; /* Keep padding for mobile */
  }

  .baby-photo {
    width: 100%; /* Take full width on mobile */
    margin-left: 0; /* Remove left margin on mobile */
    margin-top: 20px; /* Adjust top margin for mobile */
  }

  .welcome-text {
    width: 100%; /* Take full width on mobile */
    text-align: center; /* Center text for mobile */
  }

  .unique-photos-of-me-container {
    padding: 10px; /* Reduce padding for mobile */
  }

  .unique-image-container {
    width: 45%; /* Adjusted width for mobile */
    margin: 5px; /* Reduce margin for mobile */
  }

  .unique-image-overlay {
    padding: 10px; /* Keep padding for mobile */
  }
}
