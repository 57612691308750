.main-landscape {
  margin: 20px;
}

.title-landscape {
  font-size: 32px;
  font-weight: bold;
  margin: 20px auto;
  text-align: center;
}

.photo-container-landscape {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.image-container-landscape {
  width: 100%; /* Each image container takes up full width */
  margin: 10px auto; /* Center-align each image container */
  overflow: hidden;
  position: relative;
}

.image-container-landscape img {
  width: 100%;
  height: auto; /* Ensure the image maintains its aspect ratio */
  object-fit: cover; /* Crop images uniformly */
}

.image-overlay-landscape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container-landscape:hover .image-overlay-landscape {
  opacity: 1;
}

/* Media Query for mobile devices */
@media only screen and (max-width: 768px) {
  .image-container-landscape {
    width: calc(100% - 20px); /* Set to 100% width for mobile devices */
  }
}
