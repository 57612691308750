.main-portrait {
  margin: 20px;
}

.title-portrait {
  font-size: 32px;
  font-weight: bold;
  margin: 20px auto;
  text-align: center;
}

.photo-container-portrait {
  /* Styles for the photo container */
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Horizontally center the image containers */
}

.photo-container-portrait > div {
  /* Common styles for image containers */
  position: relative;
  margin: 10px;
  width: 300px; /* Set width of image container */
  height: 200px; /* Set height of image container */
  overflow: hidden; /* Hide any overflow from the image */
}

.photo-container-portrait img {
  /* Styles for the images */
  width: 100%; /* Ensure the image covers the entire container */
  height: 100%; /* Ensure the image covers the entire container */
  object-fit: cover; /* Crop the image to cover the entire container */
}

.image-container-portrait {
  position: relative;
  margin: 10px;
  width: 300px;
  height: 200px;
  overflow: hidden;
}

.image-overlay-portrait {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container-portrait:hover .image-overlay-portrait {
  opacity: 1;
}
