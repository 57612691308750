/* NavBar.css */

.nav-container { 
    height: 80px; /* Fixed height for Navbar */
    background-color: #000000; /* Example background color */
    padding: 0 20px;
    display: flex; /* Use flexbox layout */
    align-items: center; /* Vertically center content */
}

.logo-container {
    padding: 0 20px; /* Add padding around logo */
}

.logo { 
    height: 80px; /* Adjust logo height */
}

.pagelink-container { 
    margin-left: auto; /* Push pagelinks to the right */
    margin-right: 20px; /* Add right margin for spacing */
}

.link-name {
    color: #ffffff; /* Example link color */
    margin-right: 20px; /* Add right margin for spacing between links */
    text-decoration: none; /* Remove default link underline */
}

.link-name:hover {
    font-weight: bold; /* Make the link bold on hover */
}

.shopping-cart-container {
    margin-left: auto; /* Push shopping cart icon to the right */
    margin-right: 20px; /* Add right margin for spacing */
    font-size: 25px; /* Adjust the font size to increase the icon size */
}

.cart-icon { 
    color: white; 
    text-decoration: none; /* Remove default link underline */
}

.cart-icon:hover { 
    transform: scale(1.2); /* Increase size of icon on hover */
}

/* Media Query for mobile devices */
@media only screen and (max-width: 768px) {
    .nav-container {
        height: auto; /* Auto height for Navbar */
        flex-direction: column; /* Arrange items vertically */
    }

    .logo-container,
    .pagelink-container,
    .shopping-cart-container {
        width: 100%; /* Full width for each container */
        text-align: center; /* Center align content */
        margin: 10px 0; /* Add margin for spacing between items */
    }

    .logo {
        height: auto; /* Auto height for logo */
        max-width: 200px; /* Maximum width for logo */
        margin-bottom: -10px; /* Reduce margin to overlap with pagelink-container */
    }

    .link-name {
        display: inline-block; /* Display links as inline-block elements */
        margin: 10px 20px; /* Add margin for spacing between links */
        padding: 10px 20px; /* Add padding for better touch interaction */
        border-radius: 20px; /* Add border radius for modern look */
        background-color: #333333; /* Background color for links */
        transition: background-color 0.3s ease; /* Smooth transition on hover */
        color: #ffffff; /* Text color */
    }

    .link-name:hover {
        background-color: #555555; /* Darken background color on hover */
    }

    .link-name.active {
        font-weight: bold; /* Make the active link bold */
        background-color: #ffffff; /* Change background color to white */
        color: #000000; /* Change text color to black */
    }
}
